//import axios from 'axios'
import axios from '@/plugins/axios'

export default {
  getData(query) {
    return axios.get("/uploader/MembershipsList", {
      params: {
        Id: query.Id,
        Language: query.Language,
        DocName: query.DocName,
        ContractCode: query.Code
      },
    }).then((res) => {
      /* eslint-disable no-alert, no-console */
      //console.log(res)
      return ({data: res.data, status: res.status})
    }).catch((error) => {
      //console.log(error.response.status)
      return (error.response.status)
    })
  },

  getCombos(){
    return axios.get("/uploader/CombosList").then((res) => {
      //console.log(res.data)
      return ({data: res.data, status: res.status})
    }).catch((error) => {
      //console.log(error.response.status)
      return (error.response.status)
    })
  },


  uploadFile(query) {
    let fileData = new FormData()
    fileData.append("documents", query.DocFile)
    return axios.post("/uploader/UploadFile", fileData, {
      params: {
        Id: query.Id,
        Language: query.Language,
        DocName: query.DocName,
        ContractCode: query.ContractCode
      },
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      return (res.status)
    }).catch((error) => {
      return (error)
    })
  }



}
