import Vue from 'vue'
import VueRouter from 'vue-router'
//import { app } from "../firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Dashboard from '@/views/Dashboard.vue'
import Login from '@/views/Login.vue'
//import { GtmarkRoutes } from '@/modules/gtmark/router'
import { SirenisRoutes } from '@/modules/Sirenis/router'

const auth = getAuth();

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next({ name: 'home' })
        } else {
          next()
        }
      })
    }
  },
  /*     {
      path: '/gtmark',
      component: Dashboard,
      //children: [...clientRoutes]
      children: [...GtmarkRoutes]
    }, */
  {
    path: '/sirenis',
    component: Dashboard,
    children: [...SirenisRoutes],
    meta: { requiresAuth: true }
  }, 

  //Module routes
    // ...SirenisRoutes
 // ...UploadContractsRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next({ name: 'login' })
    } else {
      next()
    }
  })
})

export default router
