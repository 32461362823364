<template>
<v-container>
  <v-row class="text-center" justify="center">
    <v-col cols="8">
      <v-alert dense border="left" :value="alert" :type="alertType" transition>
        {{ alertMessage }}
      </v-alert>
    </v-col>
  </v-row>
  <v-fab-transition>
    <v-btn @click="uploadDocument()" :loading="uploadLoadingButton" color="blue-grey" dark right fab bottom fixed x-large>
      <v-icon dark>mdi-cloud-upload</v-icon>
    </v-btn>
  </v-fab-transition>

  <v-row class="text-center" justify="center">
    <v-col cols="2">
      <v-select v-model="query.Id" :items="combos.Ids" @change="getData" label="Memb. Type" dense solo hide-details='auto' clearable></v-select>
    </v-col>
    <v-col cols="2">
      <v-select v-model="query.Language" :items="combos.Language" @change="getData" label="Language" dense solo hide-details='auto' clearable></v-select>
    </v-col>
    <v-col cols="2">
      <v-select v-model="query.DocName" :items="combos.DocName" @change="getData" label="Document Name" dense solo hide-details='auto' clearable></v-select>
    </v-col>
        <v-col cols="2">
      <v-select v-model="query.SaleType" :items="combos.SaleType" @change="getData" label="Sale Type" dense solo hide-details='auto' clearable></v-select>
    </v-col>
    <v-col cols="2">
      <v-select v-model="query.Code" :items="combos.Code" item-text="SalesRoomID" item-value="ContractCode" @change="getData" label="Contract Code" dense solo hide-details='auto' clearable></v-select>
    </v-col>
  </v-row>
  <v-row class="text-center" justify="center">
    <v-col cols="12">
      <v-data-table dense :headers="headers" :items="localData" class="elevation-1" :itemsPerPage="30">
        <template v-slot:item.ContractCode="{ item }">
          <span v-if="item.ContractCode == 1">Sirmx</span>
          <span v-if="item.ContractCode == 2">Sirdom</span>
          <span v-if="item.ContractCode == 3">Matlali</span>
          <span v-if="item.ContractCode == 4">Sirpop</span>
        </template>
        <template v-slot:item.DocFile="{ item, index }">
          <div loading="true" class="UploadFile" align="center" v-cloak @drop.prevent="addFile($event, index, item)" @dragover="dragover" @dragleave="dragleave" hide-details='auto'>
            <label v-if="item.DocFile == undefined" @click="getItemIndex(index)" for="upload">
              <p class="ma-1 font-weight-bold">Drop File here<v-icon right>
                  mdi-cloud-upload
                </v-icon>
              </p>
            </label>
            <input v-if="item.DocFile == undefined" @change="onFileSelected($event)" :value="item.DocFile" type="file" accept=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document" id="upload"
              style="display: none; width: 170px">
            <v-chip style="max-width: 170px;" v-else @click:close="chip1 = false; item.DocFile = undefined" class="ma-1" close hide-details='auto' small color="primary" text-color="white"><span @dragstart="dragstart(index, $event)" draggable="true"
                class="text-truncate">{{ item.DocFile.name }}</span>
            </v-chip>
            <v-progress-linear v-if="item.DocFile !== undefined && uploadingFileProgress" color="primary" indeterminate rounded height="6"></v-progress-linear>
          </div>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Api from '../services/api'

export default {
  name: 'UploadContractsSir',
  data: () => ({
    query: {
      Id: "",
      Language: "",
      DocName: "",
      SaleType: "",
      Code: 0
    },
    headers: [{
        text: 'Membership Type',
        value: 'Id'
      },
      {
        text: 'Language',
        value: 'Language'
      },
      {
        text: 'Document Name',
        value: 'DocName'
      },
      {
        text: 'Sale Type',
        value: 'SaleType'
      },
      {
        text: 'Contract Code',
        value: 'ContractCode'
      },
      {
        text: 'File',
        value: 'DocFile'
      },
    ],
    uploadingFileProgress: false,
    uploadLoadingButton: false,
    chip1: true,
    indexRow: undefined,
    draggin: false,
    fileDraggin: undefined,
    alert: false,
    alertMessage: "",
    alertType: "warning",
    data: [],
    combos:[]
  }),

  created() {
    this.getCombos()
  },

  mounted() {
    this.getData()
  },

  computed: {
    localData() {
      return this.data.map((x) => Object.assign({
        DocFile: undefined
      }, x))
    },
  },

  methods: {
    getData() {
      Api.getData(this.query).then((res) => {
        if (res.status == 200) {
          this.data = res.data
          console.log("Data obtained successfully")
        }
        if (res.status == 404) {
          alert("Data not found");
        }
      }).catch((error) => {
        alert("Error getting data", error);
      })
    },

    getCombos() {
      Api.getCombos().then((res) => {
          if (res.status == 200) {
            this.combos = res.data
            console.log("Combos obtained successfully")
          }
          if (res.status == 404) {
            alert("Data not found")
          }
        })
        .catch((error) => {
          alert("Error getting the data", error);
        })
    },

    addFile(e, index, item) {
      event.preventDefault();
      let file
      var vm = this
      //Evaluate if the file is copied or new
      if (this.draggin) {
        file = this.fileDraggin
        this.localData[index].DocFile = file
        //console.log("File Copied ", index, file)
        this.draggin = false
      } else {
        let fileValidated = this.validateFile(e.dataTransfer.files[0])
        if (fileValidated) {
          file = e.dataTransfer.files[0]
          this.localData[index].DocFile = file
          //console.log("File Added", index, e.dataTransfer.files[0])
        }
      }
      event.currentTarget.classList.remove('green');
      event.currentTarget.classList.remove('lighten-3');
    },

    async uploadDocument() {
      //let documentsUploaded = 0
      //let totalDocuments = 0
      for (const [i, element] of this.localData.entries()) {
        if (element.DocFile) {
          //console.log("Uploading >>", element.Id, i)
          this.uploadLoadingButton = true
          this.uploadingFileProgress = true
          await Api.uploadFile(element)
            .then((status) => {
              if (status == 200) {
                //documentsUploaded++
                element.DocFile = undefined
                console.log(element.Id, " Uploaded document")
                this.uploadingFileProgress = false
              }
              if (status == 413 || status == 415) {
                element.DocFile = undefined
                this.uploadingFileProgress = false
              }
              if (status == 404) {
                alert("Data not found")
              }
            })
            .catch((error) => {
              console.log("Error uploading document ", error)
              //alert("Error al subir el documento", error);
              this.uploadLoadingButton = false
            })
        }
      }
      this.uploadLoadingButton = false
    },

    onFileSelected(e) {
      let fileValidated = this.validateFile(e.target.files[0])
      if (fileValidated) {
        let fileSelected = e.target.files[0]
        this.localData[this.indexRow].DocFile = fileSelected
        //console.log("File Added", this.indexRow, e.target.files[0])
      }
    },

    dragstart(i, e) {
      this.draggin = true
      this.fileDraggin = this.localData[i].DocFile
      event.dataTransfer.dropEffect = 'move'
    },

    dragover(e) {
      event.preventDefault();
      event.currentTarget.classList.add('green');
      event.currentTarget.classList.add('lighten-3');
    },

    dragleave(e) {
      event.currentTarget.classList.remove('green');
      event.currentTarget.classList.remove('lighten-3');
    },

    getItemIndex(index) {
      this.indexRow = index
    },
    validateFile(file) {
      var vm = this
      if (file) {
        let fileType = file.type
        let fileSize = (file.size / Math.pow(1024, 2))
        if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && fileSize <= 10) {
          return true
        } else {
          this.alertType = "warning"
          this.alert = true
          this.alertMessage = "The File it's more than 10 Mb or the type file is not correct"
          window.setTimeout(function() {
            vm.alert = false
          }, 4000)
          return false
        }
      }
    }
  }
}
</script>

<style>
       .UploadFile {
           width: 180px;
           height: 35px;
           border: 2px dotted gray;
       }
       .UploadFile h4 {
           text-align: center;
           font-family: sans-serif;
       }
   </style>
