//const axios = require('axios')
import axios from 'axios'

const defaultOptions = {
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Content-Type': 'application/json'
  }
};

let axiosInstance = axios.create(defaultOptions);

export default axiosInstance;
