<template>
  <v-list color="transparent" dense>
    <v-list-item
      v-for="item in items"
      :key="item.title"
      link
      
      active-class="blue--text"
      @click="navigateTo(item.name)"
    >
      <v-list-item-icon>
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="my-2"></v-divider>

    <v-list-item link color="grey lighten-4">
      <v-list-item-content>
        <v-list-item-title> Refresh </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Sidebar",
  components: {},
  data() {
    return {
      items: [
        {
          title: "Upload Contracts",
          icon: "mdi-view-dashboard",
          //link: "/uploadContracts",
          /*  link: {
            name: "uploadContracts",
            //params: { id: this.$route.params.id },
          }, */
          name: 'uploadContracts'
        },
        {
          title: "Personnel",
          icon: "mdi-forum",
          //link: "/personnel",
          name: 'personnel'
          /* link: {
            path: "personnel",
            params: { id: this.$route.params.id },
          }, */
         /*  link: {
            path: `${this.$route.params.id}/personnel/` 
          }, */
        },
      ],
    };
  },

  methods: {
    navigateTo(name) {
      if (this.$route.name !== name) this.$router.push({ name: name })
    },
  }
};
</script>
