// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAFokpeyZK03AJOcE2UXwUmrxceIk5Hnt0",
  authDomain: "origos-auth.firebaseapp.com",
  projectId: "origos-auth",
  storageBucket: "origos-auth.appspot.com",
  messagingSenderId: "755239774946",
  appId: "1:755239774946:web:c4794870bf7bee77a40cbb"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);