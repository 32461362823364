<template>
<div id="app">
  <router-view></router-view>
</div>
</template>

<script>
import Dashboard from '@/views/Dashboard.vue';

export default {
  name: 'app',

  components: {
    Dashboard
  }
}
</script>
