<template>
  <v-app>
    <v-main>
      <v-container class="fill-height bg" fluid v-bind:style="{
        background: `url(${backgroundUrl}) no-repeat center center`,
      }">
        <v-row align="center" justify="center">
          <v-col xs="12" sm="5" md="4" lg="3">
            <v-skeleton-loader :loading="isLoading" class="mx-auto" height="500" type="card">
              <v-card class="elevation-3" height="350">
                <v-card-text class="text-center">
                  <v-row align="center" justify="center">
                    <v-img lazy-src="/v.png" max-height="95" max-width="150" src="/v.png" contain></v-img>
                  </v-row>
                </v-card-text>

                <v-card-text>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <v-text-field ref="userID" dense solo v-model="userLogin.user" label="User ID" name="userID"
                      prepend-icon="mdi-account" type="text"></v-text-field>
                    <v-text-field v-model="userLogin.password" dense solo id="password" label="Password" name="password"
                      prepend-icon="mdi-lock" type="password" :rules="passwordRules"></v-text-field>
                    <v-combobox v-model="userLogin.client" :items="items" dense solo label="Client"
                      prepend-icon="mdi-lock"></v-combobox>
                  </v-form>
                </v-card-text>
                <v-alert type="error" :value="alert" transition="scale-transition">{{ alertMessage }}</v-alert>
                <v-card-actions>
                  <v-btn block color="primary" @click="sigIn" :loading="loadingButton" :disabled="!valid">Login</v-btn>
                </v-card-actions>
                <v-col class="text-center">
                  <v-btn small text color="primary">Recuperar contraseña</v-btn>
                </v-col>
              </v-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
import { app } from "../firebase"
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      userLogin: {
        user: "",
        password: "",
        client: ""
      },
      items: [
        'Sirenis',
        'GtMark',
      ],
      valid: false,
      backgroundUrl: "https://images.hdqwalls.com/wallpapers/blur-blue-uu.jpg", // "/miguel/bg.jpg", //https:cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg",
      alertMessage: "",
      alert: false,
      loadingButton: false,
      isLoading: null,
      emailRules: [
        (v) => !!v || "E-mail required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password required"],
      auth: getAuth(app)
    };
  },

  mounted() {
    this.$refs.userID.focus();
  },

  methods: {
    sigIn() {
      signInWithEmailAndPassword(this.auth, this.userLogin.user, this.userLogin.password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          //this.$router.push({ name: 'home' });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },

    resetAlert() {
      this.alert = false;
      this.alertMessage = "";
    },
  },
};
</script>


<style scoped>
.bg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>