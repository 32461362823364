<template>
  <v-app>
    <v-app-bar app color="white">
      <v-container class="py-0 fill-height">
        <v-avatar class="mr-10" color="grey darken-1" size="32"></v-avatar>

        <v-spacer></v-spacer>

        <v-responsive max-width="200">
          <v-select
            @change="navigate"
            :items="clients"
            item-text="name"
            item-value="to"
            label="Select Client"
            dense
            solo
            hide-details="auto"
            clearable
          ></v-select>
        </v-responsive>
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col lg="3" xl="2">
            <v-sheet rounded="lg">
              <Sidebar />
            </v-sheet>
          </v-col>

          <v-col>
            <v-sheet min-height="70vh" rounded="lg">
              <router-view></router-view>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";

export default {
  name: "dashboard",

  components: {
    Sidebar,
  },

  data: () => ({
    clients: [
      {
        name: "GtMark",
        to: "gtmark",
      },
      {
        name: "Sirenis",
        to: "sirenis",
      },
    ],
  }),

  methods: {
    navigate(to) {
      console.log(to);
      //if (to !== null) this.$router.push({ path: to })
      if (to !== null) this.$router.push({ name: to })
    },
  },
};
</script>
