import SirenisModule from "./Module.vue"
import UploadContracts from "./views/UploadContractsSir.vue"
import Personnel from "./views/PersonnelSir.vue"
import Home from "./views/HomeSir.vue"

export const SirenisRoutes = [{
  path: "/",
  component: SirenisModule,
  name: 'sirenis',
  children: [{
    path: "home",
    component: Home,
    name: "home",
    meta: { requiresAuth: true }
  },
  {
    path: "uploadContracts",
    component: UploadContracts,
    name: "uploadContracts",
    meta: { requiresAuth: true }
  },
  {
    path: "personnel",
    component: Personnel,
    name: "personnel",
    meta: { requiresAuth: true }
  }]
}]
